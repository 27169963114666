import React from 'react';
import { StyledBottom, StyledContainer } from '../../../config/styled/shared';
import { Typography } from '../../../config/styled/typography';
import { BrandingLine } from '../../icons/BrandingLine';
import { WaveBranding } from '../../icons/WaveBranding';
import * as S from './styles';

const data = [
  {
    question: 'What kind of information are we collecting?',
    answer:
      'When you fill out a form or email us, we collect information about you. <br/><br/> Filling out the form may require you to provide your name, email address, or phone number. You can, however, visit our website anonymously.',
  },
  {
    question: 'What do we use your information for?',
    answer:
      'Our company may use the information we collect from you in one of the following ways: <br/><br/> For a more personalized experience<br/> We can better serve your needs if you provide us with your information. <br/><br/> For the purpose of improving our website<br/> We continue to improve our website offerings based on the feedback and information you provide <br/><br/> To improve the customer experience<br/> We can respond to your requests and needs more effectively if you provide us with your information',
  },
  {
    question: 'How do we protect your information??',
    answer:
      'Your personal information is secured by a variety of security measures whenever you enter, send or access it.',
  },
  {
    question: 'Do we use cookies?',
    answer:
      "A cookie is a small file transferred to your computer's hard drive by your web browser (if you allow it), which allows websites or service providers to recognize your browser and collect and remember certain information.<br/><br/> We use cookies to collect aggregate data about website traffic and interaction so that we can provide better website features and tools in the future.",
  },
  {
    question: 'Do we disclose any information to outsiders?',
    answer:
      "We do not sell, trade or share your personal information with outsiders. This does not apply to trusted third parties who assist us in operating our website, conducting business, or servicing you, so long as those parties agree to keep this information confidential. <br/><br/> We may also disclose your information when we believe release is necessary to comply with the law, enforce our site policies, or protect our or others' rights, property, or safety. However, non-personally identifiable visitor information may be provided to other parties for marketing, advertising, or other uses.",
  },
  {
    question: 'Internet-only privacy policy',
    answer:
      'This Online Privacy Policy applies only to information collected through our website and not to information collected offline.',
  },
  {
    question: 'Your agreement',
    answer: 'By using our website, you agree to our website privacy policy.',
  },
  {
    question: 'Contact us',
    answer:
      'If you have any questions about this privacy policy you can contact us by any of the following ways: <br/><br/> Quijano Chambers, P.O.Box 3159, Road Town, Tortola, British Virgin Islands #1772935 Email: contact@bermond.vg',
  },
];

export const PrivacyPolicyTemplate = () => {
  return (
    <StyledBottom>
      <WaveBranding />
      <StyledContainer>
        <S.PageTop>
          <Typography.XXL as="h1" responsive css={{ color: '#ffffff', zIndex: 100 }}>
            Privacy Policy
          </Typography.XXL>
          <S.BrandingIconHolder>
            <BrandingLine />
          </S.BrandingIconHolder>
        </S.PageTop>
        <S.QAs>
          {data.map((i) => (
            <S.QA>
              <span>{i.question}</span>
              <span dangerouslySetInnerHTML={{ __html: i.answer }}></span>
            </S.QA>
          ))}
        </S.QAs>
      </StyledContainer>
    </StyledBottom>
  );
};
