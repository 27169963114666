import React from 'react';

export const WaveBranding = () => (
  <svg width="100%" height="100%" viewBox="0 0 1728 152" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M90.5 66.5C64.7484 157.34 6.98786 45.2125 0 0H1728C1724.78 7.01255 1637.11 -3.56838 1617 107.306C1601 195.5 1568.34 91.6245 1572 25.3361C1451.5 251.5 1393.38 -14.1988 1341 25.3361C1275.52 74.7547 1163 205.983 1243 107.306C1332.15 -2.66019 1044.06 169.449 1044.5 98.6616C1019.79 272.746 943 -43.2641 904 36C823.519 199.57 771.768 5.1733 614 66.5C530.819 98.8339 445 75.3009 414.9 52.4425C380.154 182.024 313.87 66.4374 274.5 107.306C130 257.306 132.5 -81.6572 90.5 66.5Z"
      fill="#FBFBFB"
    />
  </svg>
);
