import styled from '@emotion/styled';
import { mq } from '../../../config/styled/media';

export const PageTop = styled.div`
  position: relative;
  display: inline-block;
  margin: 70px 0 99px 0;
  ${mq.lt.md} {
    margin: 24px 0 12px 0;
  }
`;

export const BrandingIconHolder = styled.div`
  position: absolute;
  right: 0;
  top: 100%;
  width: 626px;
  transform: translate(35%, -50%);
  path {
    stroke-width: 8 !important;
    overflow: visible;
  }
  ${mq.lt.lg} {
    width: 220px;
    right: -10%;
  }
  ${mq.lt.md} {
    width: 283px;
    right: 5%;
    svg > path {
      stroke-width: 18;
    }
  }
  svg > path {
    stroke-width: 15;
  }
`;

export const Text = styled.p`
  font-size: 3.6rem;
  line-height: 4.3rem;
  color: #ffffff;
  margin: 42px 0;
  ${mq.lt.lg} {
    font-size: 3rem;
    line-height: 3.4rem;
  }
  ${mq.lt.md} {
    font-size: 2rem;
    line-height: 2.4rem;
    margin: 24px 0;
  }
`;

export const QAs = styled.ul`
  color: red;
  font-size: 3rem;
  list-style-type: none;
  padding: 0;
`;

export const QA = styled.li`
  color: #ffffff;
  span {
    font-weight: 400 !important;
    padding: 42px 0;
    display: block;
    ${mq.lt.md} {
      padding: 12px 0;
    }
  }
  span:first-child {
    font-size: 5.6rem;
    line-height: 6.7rem;
    ${mq.lt.lg} {
      font-size: 3.6rem;
      line-height: 4rem;
    }
    ${mq.lt.md} {
      font-size: 3.2rem;
      line-height: 3.8rem;
    }
  }
  span:last-child {
    font-size: 3.6rem;
    line-height: 4.3rem;
    ${mq.lt.lg} {
      font-size: 2.6rem;
      line-height: 3.2rem;
    }
    ${mq.lt.md} {
      font-size: 2rem;
      line-height: 2.4rem;
    }
  }
`;
