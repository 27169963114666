import React from 'react';

export const BrandingLine = () => (
  <svg width="100%" height="100%" viewBox="0 0 633 145" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M157.191 80.6903C447.299 80.4973 967.918 -44.3946 313.154 24.6193C-341.61 93.6332 189.873 153.853 542.628 139.765"
      stroke="#FF8310"
      strokeWidth="6"
    />
  </svg>
);
