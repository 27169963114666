import React from 'react';
import { Layout } from '../components/layout';
import { PrivacyPolicyTemplate } from '../components/templates/privacy-policy';

const PrivacyPolicyPage = () => {
  return (
    <Layout>
      <PrivacyPolicyTemplate />
    </Layout>
  );
};

export const Head = () => <title>Bermond Management</title>;

export default PrivacyPolicyPage;
